import { Component, computed, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';

@Component({
  selector: 'squadbox-source-navigator',
  standalone: true,
  imports: [fromSeriousSystem.UseArrowIconDirective, TranslateModule],
  template: `
    <div
      class="
      bg-shades-white 
      rounded-full 
      shadow-md
      px-4 py-2 
      border border-neutral-200 
      text-neutral-700 leading-5 
      typo-p3
      flex items-center gap-2
    "
    >
      <button
        class="disabled:opacity-50 disabled:cursor-not-allowed"
        [disabled]="!canNavigate()"
        (click)="updateIndexToSource('previous')"
      >
        <svg class="size-5" sdUseArrowIcon="chevron-small-up"></svg>
      </button>
      <span>
        {{
          'ASSISTANTS.DOCUMENT_VIEWER.SOURCE_NAVIGATOR'
            | translate
              : {
                  currentSourceIndex: currentSourceIndex() + 1,
                  totalSources: totalSources(),
                  pluralize: totalSources() > 1 ? 's' : ''
                }
        }}
      </span>
      <button
        [disabled]="!canNavigate()"
        class="disabled:opacity-50 disabled:cursor-not-allowed"
        (click)="updateIndexToSource('next')"
      >
        <svg class="size-5" sdUseArrowIcon="chevron-small-down"></svg>
      </button>
    </div>
  `,
})
export class SourceNavigatorComponent {
  public readonly currentSourceIndex = input<number>(0);
  public readonly totalSources = input<number>(0);
  public readonly currentPage = input<number>(1);
  public readonly firstPageWithPin = input<number>();

  public readonly updatedCurrentIndex = output<number>();

  private readonly hasSinglePin = computed(
    () => this.totalSources() === 1 && !!this.firstPageWithPin()
  );

  protected readonly canNavigate = computed(() => {
    if (this.totalSources() > 1) {
      return true;
    }

    if (this.hasSinglePin()) {
      return this.currentPage() !== this.firstPageWithPin();
    }

    return false;
  });

  protected updateIndexToSource(direction: 'previous' | 'next') {
    if (!this.canNavigate()) {
      return;
    }

    if (this.hasSinglePin()) {
      this.updatedCurrentIndex.emit(0);
      return;
    }

    const currentIndex = this.currentSourceIndex();
    let newIndex: number;

    if (direction === 'previous') {
      newIndex =
        currentIndex - 1 < 0 ? this.totalSources() - 1 : currentIndex - 1;
    } else {
      newIndex = currentIndex + 1 >= this.totalSources() ? 0 : currentIndex + 1;
    }

    this.updatedCurrentIndex.emit(newIndex);
  }
}
