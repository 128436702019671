export const getThumbnails = (html: HTMLElement) => {
  return Array.from(html.querySelectorAll<HTMLElement>('.thumbnail')).map(
    toThumbnail
  );
};

export const getThumbnailsWithPins = (html: HTMLElement) => {
  return getThumbnails(html).filter((thumbnail) => thumbnail.hasPin());
};

export const toThumbnail = (thumbnail: HTMLElement) => {
  return {
    pageNumber: () => Number(thumbnail.getAttribute('data-page-number') ?? 0),
    hasPin: () =>
      [null, '', undefined, 'false', false].includes(
        thumbnail.querySelector<HTMLDivElement>('.pin')?.getAttribute('hidden')
      ),
    showPin: () =>
      thumbnail
        .querySelector<HTMLDivElement>('.pin')
        ?.removeAttribute('hidden'),
    hidePin: () =>
      thumbnail
        .querySelector<HTMLDivElement>('.pin')
        ?.setAttribute('hidden', ''),
  };
};
