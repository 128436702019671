import {
  Component,
  computed,
  effect,
  inject,
  model,
  signal,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { tap } from 'rxjs';
import * as fromGenerated from '../../../_generated';
import * as fromFeatureFlags from '../../../feature-flags';
import * as fromLayout from '../../../layout';
import { fromFileUploadHelpers } from '../../assistants';
import * as fromShared from '../../shared';
import { ChatWithMessagesActions } from '../../store/chats.actions';
import { ChatsState, chatsFeature } from '../../store/chats.reducer';
import {
  DocumentViewerContentComponent,
  DocumentViewerZoomSetting,
} from './document-viewer-content.component';
import {
  DocumentViewerHeaderComponent,
  DocumentViewerZoomFactorClickedEvent,
  DocumentViewerZoomFactorLimitReached,
} from './document-viewer-header.component';
import {
  ZOOM_FACTOR_LIMIT,
  ZOOM_SETTING_DEFAULT,
} from './document-viewer.constants';
import {
  calculateNewZoomSetting,
  removeWordWrappingHyphens,
  zoomFactorToZoomSetting,
} from './document-viewer.helper';
import { SourceNavigatorComponent } from './source-navigator.component';

@Component({
  selector: 'squadbox-document-viewer-smart',
  imports: [
    DocumentViewerHeaderComponent,
    DocumentViewerContentComponent,
    fromShared.FilenameWithoutExtensionPipe,
    SourceNavigatorComponent,
    fromFeatureFlags.FeatureFlagsDirective,
  ],
  template: `
    <div
      class="hide-scrollbar w-full h-screen overflow-y-none opacity-100 border-r border-neutral-200"
    >
      @if (selectedDocumentChunksAndCitationsForSelectedDocument(); as
      documentWithChunk) {
      <squadbox-document-viewer-header
        [title]="documentWithChunk.document.filename | filenameWithoutExtension"
        [fileType]="
          fromFileUploadHelpers.getFileType(
            documentWithChunk.document.extension
          )
        "
        [zoomFactorLimitReached]="zoomFactorLimitReached()"
        [isMobile]="isMobile()"
        (zoomClicked)="handleDocumentViewerHeaderZoomClicked($event)"
        (closeClicked)="handleDocumentViewerHeaderCloseClicked()"
      ></squadbox-document-viewer-header>
      <squadbox-document-viewer-content
        [src]="document().presignedUrl.url"
        [relevantPages]="relevantPages()"
        [relevantTexts]="relevantTexts()"
        [(zoomSetting)]="zoomSetting"
        [(zoomFactor)]="zoomFactor"
        [zoomFactorLimits]="zoomFactorLimits()"
        [isMobile]="isMobile()"
        [isCitationsLoading]="isCitationsLoading()"
        [(page)]="currentPage"
        [(pagesWithVisiblePins)]="pagesWithVisiblePins"
        [(isLoading)]="isLoading"
      ></squadbox-document-viewer-content>
      @if (!isLoading()) {
      <div
        class="fixed bottom-4 left-1/2 -translate-x-1/2 z-50"
        [class]="{
          'left-1/2': isMobile(),
          'right-1/2': !isMobile()
        }"
        [squadboxFeatureFlag]="'ff-navigate-between-highlights'"
      >
        <squadbox-source-navigator
          [currentSourceIndex]="currentSourceIndex()"
          [totalSources]="totalSources()"
          [currentPage]="currentPage()"
          [firstPageWithPin]="pagesWithVisiblePins()[0]"
          (updatedCurrentIndex)="navigateToPageFromUpdatedIndex($event)"
        ></squadbox-source-navigator>
      </div>
      } }
    </div>
  `,
  styles: [``],
})
export class DocumentViewerSmartComponent {
  private readonly chatsStore = inject<Store<ChatsState>>(Store);
  private readonly layoutStore = inject<Store<fromLayout.LayoutState>>(Store);
  private readonly featureFlagsStore =
    inject<Store<fromFeatureFlags.FeatureFlagsState>>(Store);
  private readonly documentsService = inject(fromGenerated.DocumentsService);

  public readonly fromFileUploadHelpers = fromFileUploadHelpers;
  public readonly zoomSetting =
    model<DocumentViewerZoomSetting>(ZOOM_SETTING_DEFAULT);
  public readonly zoomFactor = model<number>();
  public readonly zoomFactorLimits = signal<{
    min: number;
    max: number;
  }>(ZOOM_FACTOR_LIMIT);
  public readonly zoomFactorLimitReached =
    computed<DocumentViewerZoomFactorLimitReached>(() => {
      const zoomFactor = this.zoomFactor();
      const zoomFactorLimits = this.zoomFactorLimits();

      if (zoomFactor) {
        if (zoomFactor >= zoomFactorLimits.max) {
          return 'in';
        } else if (zoomFactor <= zoomFactorLimits.min) {
          return 'out';
        }
      }

      return false;
    });

  public readonly isMobile = this.layoutStore.selectSignal(
    fromLayout.layoutFeature.selectIsMobile
  );
  private readonly ffDocumentViewerHighlightText =
    this.featureFlagsStore.selectSignal(
      fromFeatureFlags.featureFlagsFeature.selectFeatureFlag(
        'ff-document-viewer-highlight-text'
      )
    );

  public readonly selectedDocumentChunksAndCitationsForSelectedDocument =
    this.chatsStore.selectSignal(
      chatsFeature.selectSelectedDocumentWithChunksAndCitationsForSelectedDocument
    );
  public readonly document = signal<{
    chatMessageId: number | undefined;
    documentUuid: string;
    presignedUrl: {
      url: string;
      expiresAt: string;
    };
  }>({
    chatMessageId: undefined,
    documentUuid: '',
    presignedUrl: { url: '', expiresAt: '' },
  });
  public readonly relevantPages = computed(() => {
    return (
      this.selectedDocumentChunksAndCitationsForSelectedDocument()?.documentChunks.reduce(
        (acc, chunk) => [...acc, ...chunk.pageNumbers],
        [] as number[]
      ) ?? []
    );
  });
  public readonly relevantTexts = computed(() => {
    const documentWithChunksAndCitations =
      this.selectedDocumentChunksAndCitationsForSelectedDocument();

    if (
      documentWithChunksAndCitations?.documentCitations &&
      documentWithChunksAndCitations.documentCitations.length > 0
    ) {
      return documentWithChunksAndCitations.documentCitations.map((citation) =>
        removeWordWrappingHyphens(citation.content)
      );
    }

    // Fallback to chunks if citations are empty for some reason and chunks aren't
    return documentWithChunksAndCitations?.documentChunks
      ? documentWithChunksAndCitations.documentChunks
          .flatMap((chunk) => chunk.content.split('\n'))
          .filter((line) => line.trim() !== '')
          .map((line) => removeWordWrappingHyphens(line))
      : [];
  });
  public readonly isCitationsLoading = this.chatsStore.selectSignal(
    chatsFeature.selectIsCitationsLoading
  );

  public readonly currentSourceIndex = signal<number>(0);
  public readonly totalSources = signal<number>(1);
  public readonly pagesWithVisiblePins = model<number[]>([]);
  public readonly isLoading = model<boolean>(false);
  public readonly currentPage = model<number>(1);

  constructor() {
    effect(() => {
      const selectedDocumentWithChunksAndCitations =
        this.selectedDocumentChunksAndCitationsForSelectedDocument();
      const documentUuid =
        selectedDocumentWithChunksAndCitations?.document?.uuid;
      const chatMessageId =
        selectedDocumentWithChunksAndCitations?.documentChunks[0]
          ?.chatMessageId;
      const isDifferentDocument = documentUuid !== this.document().documentUuid;
      const isDifferentChatMessage =
        chatMessageId !== this.document().chatMessageId;

      if (documentUuid && (isDifferentDocument || isDifferentChatMessage)) {
        const hasDocumentChunks =
          selectedDocumentWithChunksAndCitations?.documentChunks?.length > 0;
        const hasNoCitations =
          !selectedDocumentWithChunksAndCitations?.documentCitations.length;

        // If highlight feature is enabled and we have chunks but no citations, request them
        if (
          this.ffDocumentViewerHighlightText() &&
          chatMessageId &&
          hasDocumentChunks &&
          hasNoCitations
        ) {
          this.chatsStore.dispatch(
            ChatWithMessagesActions.requestDocumentCitations({
              chatMessageId: chatMessageId,
              documentUuid: documentUuid,
            })
          );
        }

        // Reset source navigator
        this.currentSourceIndex.set(0);

        this.documentsService
          .documentsControllerGeneratePresignedUrl(documentUuid)
          .pipe(
            tap((presignedUrl) => {
              this.document.set({
                chatMessageId,
                documentUuid,
                presignedUrl: {
                  url: presignedUrl.url,
                  expiresAt: presignedUrl.expiresAt,
                },
              });
            })
          )
          .subscribe();
      }
    });

    effect(() => {
      this.totalSources.set(this.pagesWithVisiblePins().length);
    });

    effect(() => {
      const currentPageValue = this.currentPage();
      const pinsPages = this.pagesWithVisiblePins();
      const currentIndex = this.currentSourceIndex();

      if (pinsPages.length > 0) {
        // Find the pin index corresponding to the current page or the last pin passed
        let newIndex = 0;
        for (let i = 0; i < pinsPages.length; i++) {
          if (currentPageValue >= pinsPages[i]) {
            newIndex = i;
          } else {
            break;
          }
        }

        // Update only if index has changed
        if (currentIndex !== newIndex) {
          this.currentSourceIndex.set(newIndex);
        }
      }
    });
  }

  public navigateToPageFromUpdatedIndex(updatedIndex: number) {
    const pinsPages = this.pagesWithVisiblePins();

    const pageNumber = pinsPages[updatedIndex];
    this.currentPage.set(pageNumber);
  }

  public handleDocumentViewerHeaderCloseClicked() {
    this.layoutStore.dispatch(
      fromLayout.LayoutActions.setViewMode({
        viewMode: fromLayout.ViewMode.CONVERSATION,
      })
    );

    this.chatsStore.dispatch(
      ChatWithMessagesActions.unselectDocumentInChatMessage()
    );
  }

  public handleDocumentViewerHeaderZoomClicked(
    event: DocumentViewerZoomFactorClickedEvent
  ) {
    const zoomFactor = this.zoomFactor();
    if (zoomFactor) {
      const zoomSetting = zoomFactorToZoomSetting(zoomFactor);
      this.zoomSetting.set(calculateNewZoomSetting(zoomSetting, event));
    }
  }
}
