import { TranslateService } from '@ngx-translate/core';
import * as fromGenerated from '../../_generated';

/**
 * Translate the first message for a thread with an assistant that
 * isn't custom (so it's `specialized` or `essential`).
 */
export function translateFirstMessageForAssistant(
  chatWithMessages: fromGenerated.ChatWithMessagesView,
  translateService: TranslateService
): fromGenerated.ChatWithMessagesView {
  const firstMessage = chatWithMessages.messages.at(0);

  if (chatWithMessages.assistantId && firstMessage) {
    const specializedTranslationKey = `ASSISTANTS.CATEGORIES.SPECIALIZED.${firstMessage.content}`;
    const specializedTranslation = translateService.instant(
      specializedTranslationKey
    ) as string;
    if (specializedTranslationKey !== specializedTranslation) {
      chatWithMessages.messages[0].content = specializedTranslation;
    }

    const essentialTranslationKey = `ASSISTANTS.CATEGORIES.ESSENTIAL.${firstMessage.content}`;
    const essentialTranslation = translateService.instant(
      essentialTranslationKey
    ) as string;
    if (essentialTranslationKey !== essentialTranslation) {
      chatWithMessages.messages[0].content = essentialTranslation;
    }
  }

  return chatWithMessages;
}
