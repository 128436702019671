import { ClipboardModule } from '@angular/cdk/clipboard';
import {
  Component,
  inject,
  input,
  SecurityContext,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import { MarkdownPipe } from 'ngx-markdown';
import { debounceTime, Subject, tap } from 'rxjs';

const COPIED_TEXT_TIMEOUT = 3000;

@Component({
  selector: 'squadbox-message-quick-actions-copy',
  imports: [
    fromSeriousSystem.IconButtonDirective,
    fromSeriousSystem.UseUIBasicIconDirective,
    fromSeriousSystem.TooltipDirective,
    ClipboardModule,
    TranslateModule,
  ],
  providers: [MarkdownPipe],
  template: `
    <button
      sdIconButton
      variant="icon"
      color="neutral"
      size="xs"
      [sdTooltip]="tooltipText()"
      [sdTooltipAlwaysOn]="tooltipAlwaysOn()"
      (click)="onCopy()"
    >
      <svg sdUseUIBasicIcon="copy"></svg>
    </button>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class MessageQuickActionsCopyComponent {
  private readonly markdownPipe = inject(MarkdownPipe);
  private readonly sanitizer = inject(DomSanitizer);
  private readonly translateService = inject(TranslateService);
  public readonly messageContent = input.required<string>();
  private readonly copyAction$ = new Subject<void>();
  public readonly tooltipText = signal(
    this.translateService.instant('CHAT.COPY')
  );
  public readonly tooltipAlwaysOn = signal(false);

  constructor() {
    this.copyAction$
      .pipe(
        takeUntilDestroyed(),
        tap(() => {
          this.tooltipText.set(this.translateService.instant('CHAT.COPIED'));
          this.tooltipAlwaysOn.set(true);
        }),
        debounceTime(COPIED_TEXT_TIMEOUT),
        tap(() => {
          this.tooltipText.set(this.translateService.instant('CHAT.COPY'));
          this.tooltipAlwaysOn.set(false);
        })
      )
      .subscribe();
  }

  public async onCopy() {
    const html = this.sanitizer.sanitize(
      SecurityContext.HTML,
      await this.markdownPipe.transform(this.messageContent())
    );

    if (html) {
      const clipboardItem = new ClipboardItem({
        'text/html': new Blob([html], { type: 'text/html' }),
        'text/plain': new Blob([this.messageContent()], { type: 'text/plain' }),
      });
      await navigator.clipboard.write([clipboardItem]);
    }

    this.copyAction$.next();
  }
}
