import { Component, input, output } from '@angular/core';
import * as fromSeriousSystem from '@serious-system';

export type DocumentViewerZoomFactorClickedEvent = 'in' | 'out';
export type DocumentViewerZoomFactorLimitReached = 'in' | 'out' | false;

@Component({
  selector: 'squadbox-document-viewer-header',
  imports: [
    fromSeriousSystem.IconButtonDirective,
    fromSeriousSystem.UseFileTypeIconDirective,
    fromSeriousSystem.UseUIBasicIconDirective,
  ],
  template: `
    <div
      grid-area="header"
      class="flex gap-4 tablet-landscape:gap-3 items-center overflow-hidden"
    >
      <svg
        [sdUseFileTypeIcon]="fileType()"
        class="size-8 tablet-landscape:size-6"
      ></svg>
      <span
        class="typo-p2 tablet-landscape:typo-p1 font-semibold text-nowrap overflow-ellipsis overflow-hidden"
        >{{ title() }}</span
      >
    </div>
    <div grid-area="actions" class="flex">
      @if (!isMobile()) {
      <button
        sdIconButton
        variant="icon"
        color="neutral"
        size="sm"
        [disabled]="zoomFactorLimitReached() === 'out'"
        (click)="zoomClicked.emit('out')"
      >
        <svg sdUseUIBasicIcon="zoom-out"></svg>
      </button>
      <button
        sdIconButton
        variant="icon"
        color="neutral"
        size="sm"
        [disabled]="zoomFactorLimitReached() === 'in'"
        (click)="zoomClicked.emit('in')"
      >
        <svg sdUseUIBasicIcon="zoom-in"></svg>
      </button>
      }
      <button
        sdIconButton
        variant="icon"
        color="neutral"
        size="sm"
        (click)="closeClicked.emit()"
      >
        <svg sdUseUIBasicIcon="close"></svg>
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        @apply h-16 px-4;
        @apply border-b border-neutral-200;
        @apply grid grid-cols-[auto_min-content] gap-4 items-center;
        grid-template-areas: 'header actions';
      }
    `,
  ],
})
export class DocumentViewerHeaderComponent {
  public readonly title = input<string>('Document 1');
  public readonly fileType = input<fromSeriousSystem.FileTypesIconsType>('pdf');
  public readonly zoomFactorLimitReached =
    input<DocumentViewerZoomFactorLimitReached>(false);
  public readonly isMobile = input<boolean>(false);
  public readonly zoomClicked = output<DocumentViewerZoomFactorClickedEvent>();
  public readonly closeClicked = output<void>();
}
