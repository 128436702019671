import { CdkMenuTrigger } from '@angular/cdk/menu';
import { Component, computed, inject, input } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import * as fromGenerated from '../../../_generated';
import * as fromChats from '../../../chats';
import * as fromFeatureFlags from '../../../feature-flags';
import { MessageQuickActionsCopyComponent } from './message-quick-actions-copy.component';

@Component({
  selector: 'squadbox-message-quick-actions-smart',
  imports: [
    fromSeriousSystem.IconButtonDirective,
    fromSeriousSystem.UseUIBasicIconDirective,
    fromSeriousSystem.UseAiIconDirective,
    fromSeriousSystem.DropdownMenuComponent,
    fromSeriousSystem.TooltipDirective,
    CdkMenuTrigger,
    TranslateModule,
    MessageQuickActionsCopyComponent,
  ],
  template: `
    @if (ffQuickActions().copy) {
    <squadbox-message-quick-actions-copy [messageContent]="messageContent()" />
    } @if (ffQuickActions().thumbsUp) {
    <button sdIconButton variant="icon" color="neutral" size="xs">
      <svg sdUseUIBasicIcon="thumbs-up"></svg>
    </button>
    } @if (ffQuickActions().thumbsDown) {
    <button sdIconButton variant="icon" color="neutral" size="xs">
      <svg sdUseUIBasicIcon="thumbs-down"></svg>
    </button>
    } @if (ffQuickActions().starsAi.hasAnyTrueValue && !isProcessing()) {
    <button
      sdIconButton
      variant="icon"
      color="primary"
      size="xs"
      [cdkMenuTriggerFor]="starsAiDropdown.menu"
    >
      <svg sdUseAiIcon="stars"></svg>
    </button>

    <sd-dropdown-menu
      #starsAiDropdown
      [options]="starsAiDropdownMenuOptions()"
    ></sd-dropdown-menu>
    }
  `,
  styles: [
    `
      :host {
        @apply flex gap-1;
      }
    `,
  ],
})
export class MessageQuickActionsSmartComponent {
  private readonly featureFlagsStore =
    inject<Store<fromFeatureFlags.FeatureFlagsState>>(Store);
  private readonly chatsStore = inject<Store<fromChats.ChatsState>>(Store);
  private readonly translate = inject(TranslateService);
  public readonly messageId = input.required<number>();
  public readonly messageContent = input.required<string>();
  public readonly isProcessing = input.required<boolean>();
  public readonly ffQuickActions = this.featureFlagsStore.selectSignal(
    fromFeatureFlags.featureFlagsFeature.selectQuickActions
  );
  public readonly chatId = this.chatsStore.selectSignal(
    fromChats.chatsFeature.selectSelectedChatId
  );
  public readonly starsAiDropdownMenuOptions = computed<
    fromSeriousSystem.DropdownMenuOption[]
  >(() => [
    {
      label: this.translate.instant(
        'CHAT.DROPDOWN_MENU.IMPROVE_WRITING'
      ) as string,
      icon: 'edit',
      options: [
        {
          label: this.translate.instant(
            'CHAT.DROPDOWN_MENU.SIMPLIFY_LANGUAGE'
          ) as string,
          action: () => this.onTriggerStarsAiAction('QUICK_ACTION_SIMPLIFY'),
        },
        {
          label: this.translate.instant(
            'CHAT.DROPDOWN_MENU.ENHANCE_VOCABULARY'
          ) as string,
          action: () => this.onTriggerStarsAiAction('QUICK_ACTION_ENHANCE'),
        },
      ],
    },
    {
      label: this.translate.instant(
        'CHAT.DROPDOWN_MENU.MAKE_SHORTER'
      ) as string,
      action: () => this.onTriggerStarsAiAction('QUICK_ACTION_SHORTER'),
      icon: 'text-short',
    },
    {
      label: this.translate.instant('CHAT.DROPDOWN_MENU.TRANSLATE') as string,
      icon: 'translation',
      options: [
        {
          label: this.translate.instant('CHAT.DROPDOWN_MENU.ENGLISH') as string,
          action: () =>
            this.onTriggerStarsAiAction('QUICK_ACTION_TRANSLATE_TO_ENGLISH'),
        },
        {
          label: this.translate.instant('CHAT.DROPDOWN_MENU.FRENCH') as string,
          action: () =>
            this.onTriggerStarsAiAction('QUICK_ACTION_TRANSLATE_TO_FRENCH'),
        },
        {
          label: this.translate.instant('CHAT.DROPDOWN_MENU.SPANISH') as string,
          action: () =>
            this.onTriggerStarsAiAction('QUICK_ACTION_TRANSLATE_TO_SPANISH'),
        },
      ],
    },
    {
      label: this.translate.instant('CHAT.DROPDOWN_MENU.CHANGE_TONE') as string,
      icon: 'voice',
      options: [
        {
          label: this.translate.instant(
            'CHAT.DROPDOWN_MENU.PROFESSIONAL'
          ) as string,
          action: () =>
            this.onTriggerStarsAiAction('QUICK_ACTION_TONE_PROFESSIONAL'),
        },
        {
          label: this.translate.instant('CHAT.DROPDOWN_MENU.CASUAL') as string,
          action: () => this.onTriggerStarsAiAction('QUICK_ACTION_TONE_CASUAL'),
        },
        {
          label: this.translate.instant(
            'CHAT.DROPDOWN_MENU.STRAIGHTFORWARD'
          ) as string,
          action: () =>
            this.onTriggerStarsAiAction('QUICK_ACTION_TONE_STRAIGHTFORWARD'),
        },
      ],
    },
  ]);

  public onTriggerStarsAiAction(
    action: fromGenerated.RequestQuickActionDto.QuickActionEnum
  ) {
    const chatId = this.chatId();

    if (chatId) {
      this.chatsStore.dispatch(
        fromChats.ChatWithMessagesActions.requestQuickAction({
          chatId,
          chatMessageId: this.messageId(),
          quickAction: action,
          quickActionMessage: this.translate.instant(
            `CHAT.QUICK_ACTIONS_MESSAGE.${action}`
          ) as string,
        })
      );
    }
  }
}
