import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import * as fromGenerated from '../../../_generated';
import * as fromFeatureFlags from '../../../feature-flags';
import { sentryCaptureError } from '../../../sentry.helper';
import {
  AssistantActions,
  AssistantModalActions,
  AssistantsActions,
} from './assistants.actions';
import {
  filterAssistantByFeatureFlag,
  getAssistantTranslation,
} from './assistants.store.helper';

@Injectable()
export class AssistantsEffects {
  private readonly actions$ = inject(Actions);
  private readonly assistantsService = inject(fromGenerated.AssistantsService);
  private readonly translateService = inject(TranslateService);
  private readonly featureFlagsStore = inject(
    Store<fromFeatureFlags.FeatureFlagsState>
  );

  loadAssistants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssistantsActions.loadAssistants),
      withLatestFrom(
        this.featureFlagsStore.select(
          fromFeatureFlags.featureFlagsFeature
            .selectEssentialSpecializedAssistants
        )
      ),
      switchMap(([, featureFlags]) =>
        this.assistantsService.assistantsControllerGetAssistants().pipe(
          map((assistants) =>
            AssistantsActions.loadAssistantsSuccess({
              assistants: assistants
                .filter((assistant) =>
                  filterAssistantByFeatureFlag(assistant, featureFlags)
                )
                .map((assistant) => {
                  const translations = getAssistantTranslation(
                    assistant,
                    this.translateService
                  );
                  return {
                    ...assistant,
                    name: translations.name,
                    description: translations.description,
                    categoryLabel: translations.categoryLabel,
                  };
                }),
            })
          ),
          catchError((error: Error) =>
            of(
              AssistantsActions.loadAssistantsFailure({ error: error.message })
            )
          )
        )
      )
    )
  );

  onChangeFFEssentialSpecializedAssistants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFeatureFlags.FeatureFlagsActions.onChangeAssistantFlag),
      map(() => AssistantsActions.loadAssistants())
    )
  );

  onLoadFeatureFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFeatureFlags.FeatureFlagsActions.loadFeatureFlags),
      map(() => AssistantsActions.loadAssistants())
    )
  );

  saveAssistant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssistantModalActions.saveAssistant),
      switchMap(({ assistant }) =>
        this.assistantsService
          .assistantsControllerCreateAssistant(assistant)
          .pipe(
            switchMap((assistant) => [
              AssistantModalActions.saveAssistantSuccess({
                assistant: {
                  ...assistant,
                  categoryLabel: null,
                },
              }),
              AssistantModalActions.closeSaveAssistantModal(),
            ]),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            catchError((error: any) =>
              of(
                AssistantModalActions.saveAssistantFailure({
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  error: (error?.error?.name as string) ?? error?.message,
                })
              )
            )
          )
      )
    )
  );

  editAssistant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssistantModalActions.editAssistant),
      switchMap(({ uuid, assistant }) =>
        this.assistantsService
          .assistantsControllerUpdateAssistant(uuid, assistant)
          .pipe(
            switchMap((assistant) => [
              AssistantModalActions.editAssistantSuccess({
                assistant: {
                  ...assistant,
                  categoryLabel: null,
                },
              }),
              AssistantModalActions.closeSaveAssistantModal(),
            ]),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            catchError((error: any) =>
              of(
                AssistantModalActions.editAssistantFailure({
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  error: (error?.error?.name as string) ?? error?.message,
                })
              )
            )
          )
      )
    )
  );

  addAssistant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssistantActions.addAssistant),
      switchMap(({ uuid }) =>
        this.assistantsService.assistantsControllerActivateAssistant(uuid).pipe(
          map(() => AssistantActions.addAssistantSuccess({ uuid })),
          catchError((error: Error) =>
            of(AssistantActions.addAssistantFailure({ error: error.message }))
          )
        )
      )
    )
  );

  removeAssistant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssistantActions.removeAssistant),
      switchMap(({ uuid }) =>
        this.assistantsService
          .assistantsControllerDeactivateAssistant(uuid)
          .pipe(
            map(() => AssistantActions.removeAssistantSuccess({ uuid })),
            catchError((error: Error) =>
              of(
                AssistantActions.removeAssistantFailure({
                  error: error.message,
                })
              )
            )
          )
      )
    )
  );

  closeSaveAssistantModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssistantModalActions.closeSaveAssistantModal),
      map(() => AssistantActions.unselectAssistant())
    )
  );

  duplicateAssistant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssistantActions.duplicateAssistant),
      switchMap(({ uuid, name, description }) =>
        this.assistantsService
          .assistantsControllerDuplicateAssistant(uuid, { name, description })
          .pipe(
            map((assistant) =>
              AssistantActions.duplicateAssistantSuccess({
                assistant: {
                  ...assistant,
                  categoryLabel: null,
                },
              })
            ),
            sentryCaptureError,
            catchError((error: Error) =>
              of(
                AssistantActions.duplicateAssistantFailure({
                  error: error.message,
                })
              )
            )
          )
      )
    )
  );

  deleteAssistant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssistantActions.deleteAssistant),
      switchMap(({ uuid }) =>
        this.assistantsService.assistantsControllerDeleteAssistant(uuid).pipe(
          map(() => AssistantActions.deleteAssistantSuccess({ uuid })),
          sentryCaptureError,
          catchError((error: Error) =>
            of(
              AssistantActions.deleteAssistantFailure({ error: error.message })
            )
          )
        )
      )
    )
  );
}
