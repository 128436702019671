/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  HttpContextToken,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as fromGenerated from '../_generated';
import { AuthActions } from './store/auth.actions';
import { AuthState } from './store/auth.reducer';

export const IS_AUTH_INTERCEPTOR_ENABLED = new HttpContextToken(() => true);

const isInvalidTokensError = (error: any) => {
  return (
    error?.error?.statusCode === 401 &&
    (error?.error?.message?.includes('No auth token') ||
      error?.error?.message?.includes('jwt expired'))
  );
};

const isRefreshTokensError = (error: any) => {
  return (
    (error?.error?.statusCode === 400 &&
      error?.error?.message?.includes('Failed to refresh tokens')) ||
    error?.error?.message?.includes('Refresh token is missing in cookies')
  );
};

/**
 * This AuthInterceptor will intercept all HTTP requests and handle
 * the case when we catch an 401 status error that relates to the oauth tokens.
 *
 * - If it contains 'No auth token', we redirect to the login page.
 * - If it contains 'jwt expired', we refresh the tokens and replay the request.
 */
export function authInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) {
  const authService = inject(fromGenerated.AuthService);
  const store = inject(Store<AuthState>);

  if (!req.context.get(IS_AUTH_INTERCEPTOR_ENABLED)) {
    return next(req);
  }

  return next(req).pipe(
    catchError((error: any) => {
      /**
       * If the error status is 401 and its error message contains 'No auth
       * token' or 'jwt expired', we will try refreshing the tokens and replay the request.
       *
       * If there error is still the same, we redirect to the /login page.
       */
      if (isInvalidTokensError(error)) {
        return authService.authControllerRefreshTokens().pipe(
          switchMap(() => {
            store.dispatch(AuthActions.refreshTokensSuccess());
            return next(req.clone());
          }),
          catchError((refreshTokensError: any) => {
            /**
             * If the error is still the same after refreshing the tokens,
             */
            if (isInvalidTokensError(refreshTokensError)) {
              window.location.href = `${environment.appUiUrl}/login`;
            }
            return throwError(() => refreshTokensError);
          })
        );
      }

      /**
       * If the error status is 400 and its error message contains
       * 'Failed to refresh tokens', we redirect to the /login page as
       * the refresh token is invalid/expired
       */
      if (isRefreshTokensError(error)) {
        window.location.href = `${environment.appUiUrl}/login`;
        return EMPTY;
      }

      return throwError(() => error);
    })
  );
}
