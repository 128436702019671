import * as Sentry from '@sentry/angular';
import { catchError, Observable, throwError } from 'rxjs';

export const sentryCaptureError = <T>(source: Observable<T>) =>
  source.pipe(
    catchError((error: Error) => {
      Sentry.captureEvent({
        message: error.message,
        level: 'error',
        exception: {
          values: [
            {
              type: error.name,
              value: error.message,
            },
          ],
        },
      });
      return throwError(() => error);
    })
  );
