import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { ItemNavigation } from '@serious-system';
import { DateTime } from 'luxon';
import * as fromGenerated from '../../_generated';
import * as fromShared from '../shared';
import {
  ChatActions,
  ChatModalActions,
  ChatsActions,
  ChatWithMessagesActions,
} from './chats.actions';

type ChatViewWithRequiredTitle = Omit<fromGenerated.ChatView, 'title'> &
  Required<Pick<fromGenerated.ChatView, 'title'>>;

const isChatViewWithRequiredTitle = (
  chat: fromGenerated.ChatView
): chat is ChatViewWithRequiredTitle => {
  return !!chat.title;
};

export const chatsFeatureKey = 'chats';

interface ChatWithMessages extends fromGenerated.ChatView {
  messages?: fromGenerated.ChatWithMessagesView['messages'];
}

export interface ChatsState extends EntityState<ChatWithMessages> {
  selectedChatId: number | null;
  selectedChatMessageId: number | null;
  selectedDocumentId: string | null;
  isLoading: boolean;
  loaded: boolean;
  error: string | null;
  isSideContainerOpened: boolean;
  isThreadsListingContainerOpened: boolean;
  isCitationsLoading: boolean;
}

export interface ChatModalState {
  isDeleteChatModalOpened: boolean;
}

const entityAdapter: EntityAdapter<ChatWithMessages> =
  createEntityAdapter<ChatWithMessages>({
    selectId: (view) => view.id,
    sortComparer: (a, b) =>
      DateTime.fromISO(b.updatedAt).toMillis() -
      DateTime.fromISO(a.updatedAt).toMillis(),
  });

export const initialChatsState: ChatsState & ChatModalState =
  entityAdapter.getInitialState({
    selectedChatId: null,
    selectedChatMessageId: null,
    selectedDocumentId: null,
    isLoading: true,
    loaded: false,
    error: null,
    isSideContainerOpened: false,
    isThreadsListingContainerOpened: true,
    isDeleteChatModalOpened: false,
    isCitationsLoading: false,
  });

export const chatsFeature = createFeature({
  name: chatsFeatureKey,
  reducer: createReducer(
    initialChatsState,
    on(ChatModalActions.openDeleteChatModal, (state) => ({
      ...state,
      isDeleteChatModalOpened: true,
    })),
    on(ChatModalActions.closeDeleteChatModal, (state) => ({
      ...state,
      isDeleteChatModalOpened: false,
    })),
    on(ChatActions.selectChat, (state, { id }) => ({
      ...state,
      selectedChatId: id,
      error: null,
    })),
    on(ChatActions.unselectChat, (state) => ({
      ...state,
      selectedChatId: null,
      error: null,
    })),
    on(ChatActions.toggleLoading, (state, { isLoading }) => ({
      ...state,
      isLoading,
    })),
    on(ChatsActions.loadChatsSuccess, (state, { chats }) => ({
      ...entityAdapter.upsertMany(chats, state),
    })),
    on(ChatActions.loadEmptyChatSuccess, (state, { chat }) => ({
      ...entityAdapter.upsertOne(chat, { ...state, selectedChatId: chat.id }),
    })),
    on(
      ChatsActions.loadChatsFailure,
      ChatActions.loadEmptyChatFailure,
      (state, { error }) => ({
        ...state,
        error,
      })
    ),

    on(ChatsActions.deleteChat, (state, { id }) =>
      entityAdapter.removeOne(id, state)
    ),
    on(ChatsActions.deleteChatFailure, (state, { error }) => ({
      ...state,
      error,
    })),
    on(ChatWithMessagesActions.loadChatWithMessages, (state) => ({
      ...state,
      isLoading: true,
    })),
    on(
      ChatWithMessagesActions.loadChatWithMessagesSuccess,
      (state, { chatWithMessages }) =>
        entityAdapter.upsertOne(chatWithMessages, {
          ...state,
          isLoading: false,
        })
    ),
    on(
      ChatWithMessagesActions.receivedChatWithMessagesFromSocketSuccess,
      (state, { chatWithMessages }) =>
        entityAdapter.upsertOne(chatWithMessages, {
          ...state,
          isLoading: false,
        })
    ),
    on(
      ChatWithMessagesActions.loadChatWithMessagesFailure,
      (state, { error }) => ({
        ...state,
        error,
      })
    ),
    on(
      ChatWithMessagesActions.addChatMessage,
      (state, { content, assistant }) => {
        const selectedChatId = state.selectedChatId;

        if (selectedChatId !== null) {
          const currentChat = state.entities[selectedChatId];

          if (currentChat) {
            const messages = currentChat.messages?.length
              ? [...currentChat.messages]
              : [];
            const currentTime = DateTime.utc();

            if (assistant) {
              const previousTime = currentTime.minus({
                second: 1,
              });
              messages.push({
                id: crypto.getRandomValues(new Uint32Array(1))[0],
                content: assistant.description,
                isFromUser: false,
                chatId: currentChat.id,
                documentsChunks: [],
                documentsCitations: [],
                createdAt: previousTime.toISO(),
                updatedAt: previousTime.toISO(),
              });
            }

            messages.push({
              id: crypto.getRandomValues(new Uint32Array(1))[0],
              content,
              isFromUser: true,
              chatId: currentChat.id,
              documentsChunks: [],
              documentsCitations: [],
              createdAt: currentTime.toISO(),
              updatedAt: currentTime.toISO(),
            });

            return entityAdapter.updateOne(
              {
                id: currentChat.id,
                changes: {
                  updatedAt: currentTime.toISO(),
                  _count: {
                    ...currentChat._count,
                    messages: messages.length,
                  },
                  messages,
                },
              },
              state
            );
          }
        }

        return state;
      }
    ),
    on(
      ChatWithMessagesActions.requestQuickAction,
      (state, { chatId, quickActionMessage }) => {
        const chat = state.entities[chatId];

        if (chat) {
          const currentTime = DateTime.utc();
          const messages = chat.messages?.length ? [...chat.messages] : [];

          messages.push({
            id: crypto.getRandomValues(new Uint32Array(1))[0],
            content: quickActionMessage,
            isFromUser: true,
            chatId: chat.id,
            documentsChunks: [],
            documentsCitations: [],
            createdAt: currentTime.toISO(),
            updatedAt: currentTime.toISO(),
          });

          return entityAdapter.updateOne(
            {
              id: chat.id,
              changes: {
                updatedAt: currentTime.toISO(),
                _count: {
                  ...chat._count,
                  messages: messages.length,
                },
                messages,
              },
            },
            state
          );
        }
        return state;
      }
    ),
    on(ChatWithMessagesActions.addChatMessageFailure, (state, { error }) => ({
      ...state,
      error,
    })),
    on(
      ChatWithMessagesActions.selectDocumentInChatMessage,
      (state, { chatMessageId, documentUuid }) => ({
        ...state,
        selectedChatMessageId: chatMessageId,
        selectedDocumentId: documentUuid,
        error: null,
      })
    ),
    on(ChatWithMessagesActions.unselectDocumentInChatMessage, (state) => ({
      ...state,
      selectedChatMessageId: null,
      selectedDocumentId: null,
    })),
    on(ChatActions.openSideContainer, (state) => ({
      ...state,
      isSideContainerOpened: true,
    })),
    on(ChatActions.closeSideContainer, (state) => ({
      ...state,
      isSideContainerOpened: false,
    })),
    on(ChatActions.openThreadsListingContainer, (state) => ({
      ...state,
      isThreadsListingContainerOpened: true,
    })),
    on(ChatActions.closeThreadsListingContainer, (state) => ({
      ...state,
      isThreadsListingContainerOpened: false,
    })),
    on(
      ChatWithMessagesActions.receivedDocumentCitationsFromSocketSuccess,
      (state, { chatId, chatMessageId, documentCitations }) => {
        const chat = state.entities[chatId];

        if (!chat?.messages?.length) {
          return state;
        }

        if (chat.messages.some((message) => message.id === chatMessageId)) {
          const now = DateTime.utc();

          const updatedMessages = chat.messages.map((message) =>
            message.id === chatMessageId
              ? {
                  ...message,
                  updatedAt: now.toISO(),
                  documentsCitations: [
                    ...message.documentsCitations,
                    ...documentCitations.filter(
                      (newCitation) =>
                        !message.documentsCitations.some(
                          (existingCitation) =>
                            existingCitation.uuid === newCitation.uuid
                        )
                    ),
                  ],
                }
              : message
          );

          return entityAdapter.updateOne(
            {
              id: chatId,
              changes: {
                updatedAt: now.toISO(),
                messages: updatedMessages,
              },
            },
            { ...state, isCitationsLoading: false, error: null }
          );
        }

        return state;
      }
    ),
    on(ChatWithMessagesActions.requestDocumentCitations, (state) =>
      state.selectedChatId
        ? {
            ...state,
            isCitationsLoading: true,
          }
        : state
    ),
    on(
      ChatWithMessagesActions.requestDocumentCitationsFailure,
      (state, { error }) => ({
        ...state,
        error,
        isCitationsLoading: false,
      })
    ),
    on(ChatWithMessagesActions.setCitationsLoading, (state, { isLoading }) => ({
      ...state,
      isCitationsLoading: isLoading,
    })),
    on(ChatsActions.deleteManyChatsSuccess, (state, { ids }) =>
      entityAdapter.removeMany(ids, state)
    )
  ),
  extraSelectors: ({
    selectChatsState,
    selectSelectedChatId,
    selectSelectedChatMessageId,
    selectSelectedDocumentId,
  }) => {
    const entitySelectors = entityAdapter.getSelectors(selectChatsState);

    const selectSelectedChatMessage = createSelector(
      selectSelectedChatId,
      selectSelectedChatMessageId,
      entitySelectors.selectEntities,
      (selectedChatId, selectedChatMessageId, entities) =>
        selectedChatId && selectedChatMessageId
          ? entities[selectedChatId]?.messages?.find(
              (message) => message.id === selectedChatMessageId
            )
          : null
    );

    return {
      ...entitySelectors,
      selectChatsAsItemNavigation: createSelector(
        selectSelectedChatId,
        entitySelectors.selectAll,
        (selectedChatId, chats) =>
          /**
           * We are filtering out chats without a title because we don't want to
           * show them in the navigation. Note that we should only have 1 chat
           * per user that doesn't have a title, which is the "Home Chat" up until
           * a message has been added to it.
           */
          chats
            .filter((chat) => chat._count.messages)
            .filter(isChatViewWithRequiredTitle)
            .map<ItemNavigation>(
              (chat) =>
                ({
                  id: chat.id,
                  title: chat.title,
                  selected: chat.id === selectedChatId,
                  date: DateTime.fromISO(chat.updatedAt).toLocal(),
                } satisfies ItemNavigation)
            )
      ),
      selectSelectedChat: createSelector(
        selectSelectedChatId,
        entitySelectors.selectEntities,
        (selectedChatId, entities) =>
          selectedChatId ? entities[selectedChatId] : null
      ),
      selectDocumentsForSelectedChatMessage: createSelector(
        selectSelectedChatMessage,
        (selectedChatMessage) =>
          selectedChatMessage?.documentsChunks
            ? fromShared.getUniqueDocuments(selectedChatMessage.documentsChunks)
            : null
      ),
      selectSelectedDocumentWithChunksAndCitationsForSelectedDocument:
        createSelector(
          selectSelectedChatMessage,
          selectSelectedDocumentId,
          (selectedChatMessage, selectedDocumentId) => {
            if (!selectedChatMessage || !selectedDocumentId) {
              return null;
            }

            const documentChunksForSelectedDocument =
              selectedChatMessage.documentsChunks.filter(
                ({ document }) => document.uuid === selectedDocumentId
              );

            if (!documentChunksForSelectedDocument.length) {
              return null;
            }

            const documentCitationsForSelectedDocument =
              selectedChatMessage.documentsCitations.filter(
                ({ documentId }) => documentId === selectedDocumentId
              );

            return {
              document: documentChunksForSelectedDocument[0].document,
              documentChunks: documentChunksForSelectedDocument,
              documentCitations: documentCitationsForSelectedDocument,
            };
          }
        ),
      selectSelectedDocumentInChatMessage: createSelector(
        selectSelectedChatMessageId,
        selectSelectedDocumentId,
        (selectedChatMessageId, selectedDocumentId) => ({
          chatMessageId: selectedChatMessageId,
          documentUuid: selectedDocumentId,
        })
      ),
    };
  },
});
