import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import { DateTime } from 'luxon';
import { tap } from 'rxjs';
import { AssistantView } from '../../_generated';
import * as fromLayout from '../../layout';
import * as fromAssistants from '../assistants';
import { ChatsHelper } from '../chats.helper';
import { TopBarMobileResponsive } from '../components/top-bar.mobile.responsive';
import {
  ChatActions,
  ChatModalActions,
  ChatWithMessagesActions,
  SelectDocumentInChatMessagePayload,
} from '../store/chats.actions';
import { ChatsState, chatsFeature } from '../store/chats.reducer';
import {
  MessageListingType,
  MessagesListingComponent,
} from './components/messages-listing.component';
import { NewConversationComponent } from './components/new-conversation.component';

export interface DocumentInChatMessage {
  chatMessageId: number | null;
  documentUuid: string | null;
}

@Component({
  selector: 'squadbox-conversation',
  imports: [
    TranslateModule,
    fromSeriousSystem.ButtonDirective,
    fromSeriousSystem.IconButtonDirective,
    fromSeriousSystem.UseArrowIconDirective,
    fromSeriousSystem.UseLogoDirective,
    fromSeriousSystem.UseAiIconDirective,
    fromSeriousSystem.UseIllustrationDirective,
    fromSeriousSystem.DrawerComponent,
    fromSeriousSystem.OptionMenuComponent,
    fromSeriousSystem.PromptInputComponent,
    fromSeriousSystem.TooltipDirective,
    NewConversationComponent,
    TopBarMobileResponsive,
    MessagesListingComponent,
  ],
  providers: [ChatsHelper],
  template: `
    <div
      chat
      class="grid grid-rows-[min-content_auto_min-content] h-full w-full max-h-svh"
    >
      @if (isMobile()){
      <squadbox-top-bar-mobile
        [title]="
          selectedAssistant() ? selectedAssistant()?.name : selectedChat().title
        "
        [hideMoreOptionsButton]="!selectedChat().messages?.length"
        (chevronLeftButtonClicked)="toggleThreadsListingContainer()"
        (moreOptionsClicked)="isMoreOptionsOpened.set(true)"
      ></squadbox-top-bar-mobile>
      } @else {
      <div class="w-full h-14"></div>
      }
      <div #messageContainer class="flex-grow overflow-y-auto">
        <div
          class="text-h5 flex tablet-landscape:pt-14 justify-center gap-2 w-full h-full"
        >
          <!-- New Conversation (without an assistant) -->
          @if ( selectedChat()._count?.messages === 0 && !selectedAssistant() )
          {
          <squadbox-new-conversation
            (chatMessageAdded)="addChatMessage($event)"
          ></squadbox-new-conversation>
          } @else {

          <!-- Messages List -->
          <squadbox-messages-listing
            [messages]="selectedChatMessages()"
            [isLoading]="isLoading()"
            [isThinking]="isThinking()"
            [isMobile]="isMobile()"
            [messageCount]="
              selectedChat()._count?.messages || selectedChatMessages().length
            "
            [selectedDocumentInChatMessage]="selectedDocumentInChatMessage()"
            (documentInChatMessageClicked)="
              handleDocumentInChatMessageClicked($event)
            "
          ></squadbox-messages-listing>
          }
        </div>
      </div>

      <div class="w-full tablet-landscape:max-w-180 bg-shades-white mx-auto">
        <div
          class="
            flex flex-col justify-center w-full h-auto
            px-4 py-2
            tablet-landscape:items-center tablet-landscape:px-0 tablet-landscape:py-4 tablet-landscape:pt-0 tablet-landscape:pb-6
          "
        >
          <!-- SCROLL BUTTON -->
          @if (showScrollButton()) {
          <button
            sdIconButton
            variant="outlined"
            color="neutral"
            size="xs"
            class="fixed m-auto place-self-center -translate-y-16 z-30 bg-shades-white"
            (click)="scrollToBottom('smooth')"
          >
            <svg sdUseArrowIcon="arrow-down"></svg>
          </button>
          }
          <!-- INPUT PROMPT -->
          <sd-prompt-input
            [disabled]="!isAssistantReady() || isThinking()"
            (promptSubmitted)="addChatMessage($event)"
            [hasVoiceToText]="true"
            [sdTooltip]="
              !isAssistantReady() &&
              ('PROMPT_INPUT.TOOLTIP.UNAVAILABLE_ASSISTANT' | translate)
            "
            [sdTooltipAlwaysOn]="true"
          ></sd-prompt-input>
        </div>
      </div>
    </div>

    <!-- More options -->
    @if(isMobile()) {
    <sd-drawer
      class="z-10"
      [isOpen]="isMoreOptionsOpened()"
      (closeClicked)="isMoreOptionsOpened.set(false)"
    >
      @for (option of drawerOptions(); track option.label) {
      <sd-option-menu
        [option]="option"
        (triggerAction)="onTriggerAction($event)"
      ></sd-option-menu>
      }
    </sd-drawer>
    }
  `,
  styles: [
    `
      ::-webkit-scrollbar {
        display: none;
      }
    `,
  ],
})
export class ConversationPage implements AfterViewInit {
  @ViewChild('messageContainer') messageContainer?: ElementRef<HTMLDivElement>;
  // Not the best thing to do, it breaks the SOLID principle
  // But we are ok to manage it instead of having a service
  // For the selectedChat and what it into the promptInput component.
  // I prefer to keep the responsibility of clear the input in the chat component
  @ViewChild(fromSeriousSystem.PromptInputComponent)
  promptInput!: fromSeriousSystem.PromptInputComponent;

  private readonly chatsStore = inject<Store<ChatsState>>(Store);
  private readonly assistantsStore =
    inject<Store<fromAssistants.AssistantsState>>(Store);
  private readonly layoutStore = inject<Store<fromLayout.LayoutState>>(Store);
  private readonly chatsHelper = inject(ChatsHelper);
  private readonly actions$ = inject(Actions);
  private readonly selectedChatMessagesLength = signal(0);

  public readonly isThinking = signal(false);
  public readonly showScrollButton = signal(false);

  public readonly isThreadsListingContainerOpened =
    this.chatsStore.selectSignal(
      chatsFeature.selectIsThreadsListingContainerOpened
    );
  public readonly isMobile = this.layoutStore.selectSignal(
    fromLayout.layoutFeature.selectIsMobile
  );
  public readonly isDocViewerMode = this.layoutStore.selectSignal(
    fromLayout.layoutFeature.selectIsDocViewerMode
  );
  public readonly selectedChat = computed(() => {
    const selectedChat = this.chatsStore.selectSignal(
      chatsFeature.selectSelectedChat
    );

    return {
      ...selectedChat(),
      title: this.chatsHelper.getChatTitle(selectedChat()?.title),
    };
  });
  public readonly selectedAssistant = this.assistantsStore.selectSignal(
    fromAssistants.assistantsFeature.selectSelectedAssistant
  );
  public readonly selectedChatMessages = computed(() => {
    const messages = this.selectedChat().messages ?? [];
    const assistant = this.selectedAssistant();

    /**
     * If the assistant is selected and there are no messages,
     * add a first message to the messages list with the assistant description.
     */
    if (assistant && messages.length === 0) {
      return [
        {
          id: -1,
          content: assistant.description,
          isFromUser: false,
        },
      ] satisfies MessageListingType[];
    }

    return [...messages].sort(
      (a, b) =>
        DateTime.fromISO(a.createdAt).toMillis() -
        DateTime.fromISO(b.createdAt).toMillis()
    );
  });
  public readonly isLoading = this.chatsStore.selectSignal(
    chatsFeature.selectIsLoading
  );
  public readonly selectedDocumentInChatMessage = this.chatsStore.selectSignal(
    chatsFeature.selectSelectedDocumentInChatMessage
  );

  public readonly isAssistantReady = computed(() => {
    const isAssistantConversation = this.selectedAssistant();

    if (!isAssistantConversation) {
      return true;
    }

    return this.selectedAssistant()?.status === AssistantView.StatusEnum.ready;
  });
  public readonly isMoreOptionsOpened = signal(false);
  public readonly drawerOptions = signal<fromSeriousSystem.OptionMenu[]>(
    this.chatsHelper.getChatDrawerOptions()
  );

  constructor() {
    let previousSelectedChat = this.selectedChat();

    effect(
      () => {
        const messagesLength = this.selectedChat().messages?.length ?? 0;

        if (this.hasMessagesLengthChanged(messagesLength)) {
          this.handleNewMessages(messagesLength);
        }

        this.updateScrollButtonVisibility();
      },
      { allowSignalWrites: true }
    );

    effect(
      () => {
        if (
          this.selectedChat() &&
          this.selectedChat() !== previousSelectedChat
        ) {
          this.promptInput.clear();
          this.scrollToBottom();
        }

        previousSelectedChat = this.selectedChat();
      },
      { allowSignalWrites: true }
    );

    this.actions$
      .pipe(
        ofType(ChatWithMessagesActions.requestQuickAction),
        takeUntilDestroyed(),
        tap(() => this.isThinking.set(true))
      )
      .subscribe();
    this.actions$
      .pipe(
        ofType(ChatWithMessagesActions.requestQuickActionFailure),
        takeUntilDestroyed(),
        tap(() => this.isThinking.set(false))
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(
          ChatWithMessagesActions.receivedChatWithMessagesFromSocketFailure
        ),
        takeUntilDestroyed(),
        tap(() => this.isThinking.set(false))
      )
      .subscribe();
  }

  ngAfterViewInit() {
    const messageContainer = this.messageContainer?.nativeElement;
    if (messageContainer) {
      messageContainer.addEventListener('scroll', () => {
        this.showScrollButton.set(
          messageContainer.scrollTop <
            messageContainer.scrollHeight - messageContainer.clientHeight
        );
      });
    }
  }

  public scrollToBottom(behavior: 'smooth' | 'auto' = 'auto'): void {
    try {
      const messageContainer = this.messageContainer?.nativeElement;
      if (messageContainer) {
        setTimeout(() => {
          messageContainer.scrollTo({
            top: messageContainer.scrollHeight,
            behavior,
          });
        }, 0);
      }
    } catch (err) {
      console.error('Could not scroll to bottom:', err);
    }
  }

  public addChatMessage(content: string): void {
    const selectedAssistant = this.selectedAssistant();

    this.isThinking.set(true);

    this.chatsStore.dispatch(
      ChatWithMessagesActions.addChatMessage(
        selectedAssistant
          ? {
              content,
              assistant: selectedAssistant,
            }
          : { content }
      )
    );
  }

  private hasMessagesLengthChanged(messagesLength: number): boolean {
    return messagesLength !== this.selectedChatMessagesLength();
  }

  private handleNewMessages(messagesLength: number): void {
    const isLastMessageFromChat =
      !this.selectedChat().messages?.[messagesLength - 1]?.isFromUser;

    if (isLastMessageFromChat) {
      this.isThinking.set(false);
    }

    this.selectedChatMessagesLength.set(messagesLength);

    setTimeout(() => {
      this.scrollToBottom();
      this.updateScrollButtonVisibility();
    }, 0);
  }

  private updateScrollButtonVisibility(): void {
    const messageContainer = this.messageContainer?.nativeElement;
    if (messageContainer) {
      this.showScrollButton.set(
        messageContainer.scrollTop <
          messageContainer.scrollHeight - messageContainer.clientHeight
      );
    }
  }

  public toggleThreadsListingContainer() {
    if (this.isThreadsListingContainerOpened()) {
      this.chatsStore.dispatch(ChatActions.closeThreadsListingContainer());
    } else {
      this.chatsStore.dispatch(ChatActions.openThreadsListingContainer());
    }
  }

  public onTriggerAction(action: string) {
    if (action === 'delete') {
      this.chatsStore.dispatch(ChatModalActions.openDeleteChatModal());
    }
  }

  public handleDocumentInChatMessageClicked(
    event: SelectDocumentInChatMessagePayload
  ) {
    this.chatsStore.dispatch(
      ChatWithMessagesActions.selectDocumentInChatMessage({
        chatMessageId: event.chatMessageId,
        documentUuid: event.documentUuid,
      })
    );

    this.layoutStore.dispatch(
      fromLayout.LayoutActions.setViewMode({
        viewMode: 'doc-viewer',
      })
    );

    setTimeout(() => {
      this.scrollToSelectedMessage();
    }, 0);
  }

  public scrollToSelectedMessage(): void {
    const selectedDoc = this.selectedDocumentInChatMessage();

    if (selectedDoc?.chatMessageId === null) {
      return;
    }

    const messageIdElement = document.getElementById(
      `message-${selectedDoc.chatMessageId}`
    );

    if (!messageIdElement) {
      return;
    }

    messageIdElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }
}
