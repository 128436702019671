import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NgClass } from '@angular/common';
import {
  Component,
  HostBinding,
  OnInit,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import * as fromGenerated from '../../_generated';
import * as fromAssistantIcon from '../assistants/components/assistant-icon.helper';
import { ChatsHelper } from '../chats.helper';

@Component({
  selector: 'squadbox-threads-listing-desktop',
  imports: [
    NgClass,
    TranslateModule,
    RouterModule,
    fromSeriousSystem.ItemsNavigationComponent,
    fromSeriousSystem.UseNavigationIconDirective,
    fromSeriousSystem.UseIllustrationDirective,
    fromSeriousSystem.IconButtonDirective,
    fromSeriousSystem.AvatarDirective,
    fromSeriousSystem.UseAssistantCustomIconDirective,
    fromSeriousSystem.UseAssistantIconDirective,
  ],
  template: `
    <div
      class="
        hide-scrollbar h-screen bg-shades-white overflow-y-auto
        transform transition-[transform,opacity,width] ease-in-out duration-700
      "
      [ngClass]="{
        '-translate-x-full opacity-0 w-16': !isThreadsListingContainerOpened(),
        'translate-x-0 opacity-100 w-70 desktop-xl:w-110':
          isThreadsListingContainerOpened()
      }"
    >
      <!-- Header/ Topbar -->
      <div
        class="flex h-14 justify-between items-center px-4 sticky top-0 left-0 right-0 bg-shades-white z-10"
      >
        @if (isThreadsListingContainerOpened()) {
        <button
          sdIconButton
          variant="icon"
          color="neutral"
          size="sm"
          class="ml-1"
          (click)="toggleThreadsListingContainerClicked.emit()"
        >
          <svg sdUseNavigationIcon="sidebar"></svg>
        </button>
        <button
          sdIconButton
          variant="icon"
          color="primary"
          size="sm"
          [@moveIcon]="isThreadsListingContainerOpened()"
          (click)="newConvoClicked.emit()"
        >
          <svg sdUseNavigationIcon="new-chat"></svg>
        </button>
        }
      </div>

      <!-- Threads Listing -->
      <div class="flex flex-col py-6 gap-8">
        <!-- Chats Main Navigation Items (assistants, ...) -->
        <div class="flex flex-col gap-1">
          @for (assistant of assistants(); track assistant.uuid) {
          <a
            class="main-navigation-item typo-p3 py-2"
            (click)="newConvoClicked.emit({ assistantUuid: assistant.uuid })"
          >
            <div
              class="
                flex items-center justify-center
                outline outline-primary-500/15  outline-offset-2 rounded-full
                bg-primary-500/5
              "
            >
              @switch (fromAssistantIcon.getLogoType(assistant.logo)) { @case
              ('assistant') {
              <svg
                [sdUseAssistantIcon]="
                  fromAssistantIcon.getAssistantIcon(assistant.logo)
                "
                class="size-4 text-primary-500/30"
              ></svg>
              } @case ('custom') {
              <svg
                [sdUseAssistantCustomIcon]="
                  fromAssistantIcon.getAssistantCustomIcon(assistant.logo)
                "
                class="size-4 text-primary-500/30"
              ></svg>
              } @default {
              <img [src]="assistant.logo" />
              } }
            </div>
            <span
              class="font-semibold break-words overflow-hidden max-w-full"
              >{{ assistant.name }}</span
            >
          </a>
          }

          <!-- Explore My Assistants -->
          <a
            class="main-navigation-item typo-p3 py-2 overflow-ellipsis whitespace-nowrap"
            routerLink="/chats/assistants"
          >
            <svg sdUseNavigationIcon="dashboard"></svg>
            <span>{{ 'MAIN_NAVIGATION_ITEMS.ASSISTANTS' | translate }}</span>
          </a>
        </div>

        <!-- Chats Navigation Items (chats) -->
        <sd-items-navigation
          [items]="itemNavigations()"
          [dropdownMenuOptions]="dropdownMenuOptions()"
          [(activeDropdownItemId)]="activeItemIdForDropdown"
          (itemClicked)="itemClicked.emit($event)"
        ></sd-items-navigation>
      </div>
    </div>
  `,
  animations: [
    trigger('moveIcon', [
      transition(':enter', [
        animate(
          '500ms',
          keyframes([
            style({ transform: 'translate(-11rem, 4rem)', offset: 0 }),
            style({ transform: 'translate(-9.9rem, 3.6rem)', offset: 0.1 }),
            style({ transform: 'translate(-8.8rem, 3.2rem)', offset: 0.2 }),
            style({ transform: 'translate(-7.7rem, 2.8rem)', offset: 0.3 }),
            style({ transform: 'translate(-6.6rem, 2.4rem)', offset: 0.4 }),
            style({ transform: 'translate(-5.5rem, 2rem)', offset: 0.5 }),
            style({ transform: 'translate(-4.4rem, 1.6rem)', offset: 0.6 }),
            style({ transform: 'translate(-3.3rem, 1.2rem)', offset: 0.7 }),
            style({ transform: 'translate(-2.2rem, 0.8rem)', offset: 0.8 }),
            style({ transform: 'translate(-1.1rem, 0.4rem)', offset: 0.9 }),
            style({ transform: 'translate(0, 0)', offset: 1 }),
          ])
        ),
      ]),
    ]),
  ],
  styles: [
    `
      :host {
        @apply block w-min z-10 pt-3;
        &.opened {
          @apply border-r border-neutral-200;
        }
      }
    `,
  ],
})
export class ThreadsListingDesktopResponsive implements OnInit {
  private readonly chatsHelper = inject(ChatsHelper);
  protected readonly fromAssistantIcon = fromAssistantIcon;

  public activeItemIdForDropdown: number | null = null;

  public readonly assistants = input.required<fromGenerated.AssistantView[]>();
  public readonly itemNavigations =
    input.required<fromSeriousSystem.ItemNavigation[]>();
  public readonly isThreadsListingContainerOpened = input.required<boolean>();

  public readonly toggleThreadsListingContainerClicked = output();
  public readonly newConvoClicked = output<{ assistantUuid: string } | void>();
  public readonly chatDeleteClicked = output<number>();
  public readonly itemClicked = output<fromSeriousSystem.ItemNavigation>();

  public readonly dropdownMenuOptions = signal<
    fromSeriousSystem.DropdownMenuOption[]
  >(
    this.chatsHelper.getChatDropdownMenuDrawerOptions({
      delete: () => {
        if (this.activeItemIdForDropdown) {
          this.chatDeleteClicked.emit(this.activeItemIdForDropdown);
        }
      },
    })
  );

  private isFirstLoad = true;

  /**
   * Disable the animation on the first load or page refresh.
   * - https://angular.dev/guide/animations/transition-and-triggers#disable-all-animations
   */
  @HostBinding('@.disabled')
  get disableAnimationOnFirstLoad() {
    return this.isFirstLoad;
  }

  @HostBinding('class.opened')
  get isOpened() {
    return this.isThreadsListingContainerOpened();
  }

  ngOnInit(): void {
    setTimeout(() => (this.isFirstLoad = false));
  }
}
