import { Component, computed, inject, output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import * as fromAuth from '../../../auth';

@Component({
  selector: 'squadbox-new-conversation',
  imports: [
    TranslateModule,
    fromSeriousSystem.UseLogoDirective,
    fromSeriousSystem.UseAssistantIconDirective,
  ],
  template: `
    <div class="flex flex-col items-center justify-center gap-8 h-full">
      <div
        class="flex flex-col items-center justify-center gap-8 max-tablet-landscape:mt-auto"
      >
        <img class="h-14" sdUseLogo="logo-minimal-filled" />

        <div class="flex flex-col items-center text-center">
          <span class="typo-h6 font-semibold leading-8"
            >{{ 'CHAT.HELLO' | translate }} {{ userName() }}</span
          >
          <span class="typo-p3 text-neutral-500 leading-5 text-center">{{
            'CHAT.WELCOME_MESSAGE' | translate
          }}</span>
        </div>
      </div>

      <div
        class="
          flex flex-row gap-4
          p-4 max-tablet-landscape:mt-auto
          w-screen tablet-landscape:max-w-180
          max-tablet-landscape:overflow-scroll scrollbar-none"
      >
        @for (prePrompt of prePrompts(); track prePrompt.title) {
        <div
          class="
            flex flex-row tablet-landscape:flex-col gap-4 tablet-landscape:gap-2
            bg-primary-500/10 hover:bg-primary-500/15
            rounded-3xl
            p-4 w-full
            max-tablet-landscape:min-w-70
            cursor-pointer
          "
          (click)="chatMessageAdded.emit(prePrompt.prompt)"
        >
          <svg
            [sdUseAssistantIcon]="prePrompt.icon"
            class="
                text-primary-500 size-5
                max-tablet-landscape:min-w-5 max-tablet-landscape:self-center
              "
          ></svg>
          <div class="typo-p2 tablet-landscape:typo-p3">
            {{ prePrompt.title }}
          </div>
        </div>
        }
      </div>
    </div>
  `,
  styles: [``],
})
export class NewConversationComponent {
  private readonly authStore = inject<Store<fromAuth.AuthState>>(Store);
  private readonly translateService = inject(TranslateService);
  private readonly user = this.authStore.selectSignal(
    fromAuth.authFeature.selectUser
  );

  public readonly chatMessageAdded = output<string>();
  public readonly userName = computed(() => {
    return `${this.user()?.firstName}`;
  });

  prePrompts = computed(() => {
    const promptsArray = [];

    for (let i = 1; i < 5; i++) {
      promptsArray.push({
        icon: this.translateService.instant(
          `PRE_PROMPTS.${i}.icon`
        ) as fromSeriousSystem.AssistantIconsType,
        title: this.translateService.instant(
          `PRE_PROMPTS.${i}.title`
        ) as string,
        prompt: this.translateService.instant(
          `PRE_PROMPTS.${i}.prompt`
        ) as string,
      });
    }
    return promptsArray;
  });
}
